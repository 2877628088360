<template>
  <div class="manage-branch animatedBox">
    <div class="container fluid">
      <!-- =========================== Filter ============================ -->
      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Branch Name"
            name="name"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['registerEstateAgentName[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Register Estate Agent Name"
            name="registerEstateAgentName"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['businessRegisterNumber']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Board Reg. No."
            name="businessRegisterNumber"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['email']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Email"
            name="email"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
          <fd-input
            v-model="filter['address[partial]']"
            class="col-12 px-1 mb-2"
            label="Address"
            name="address"
            type="text"
            @keyup.native.enter="filterData()"
          ></fd-input>
        </div>
      </filter-panel>
      <!-- ============================ Table ============================ -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="branchTableColumns"
          :rows="branchTableData"
          :totalRows="branchTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="openBranchDetail"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button v-if="canAdd" class="btn main mr-2" @click="toAddBranch">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" @click.stop>
              <dropdown-button buttonClass="circle flat">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <div class="card text-left">
                  <fd-list-item @click="toEditBranch(props.row.id)">
                    <i class="fas fa-pen mr-1"></i>
                    Edit
                  </fd-list-item>
                  <fd-list-item @click="toManageBankAccounts(props.row.id)">
                    <i class="fas fa-money-check mr-1"></i>
                    Bank Accounts
                  </fd-list-item>
                </div>
              </dropdown-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- ======================= Branch Detail Modal ======================= -->
    <modal v-model="isBranchDetailShown">
      <branch-detail
        :detail="currentBranchDetail"
        @close="isBranchDetailShown = false"
      ></branch-detail>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-branch",
  mixins: [vueGoodTable],
  components: {
    BranchDetail: () => import("./BranchDetail"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  watch: {
    canEdit(val) {
      this.branchTableColumns[0].hidden = !val;
    }
  },
  data() {
    return {
      canEdit: false,
      canAdd: false,
      canDelete: false,
      branchTableData: [],
      branchTablePagination: {},
      branchTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Address",
          field: "address"
        },
        {
          label: "Register Estate Agent Name",
          field: "registerEstateAgentName"
        },
        {
          label: "Board Reg. No.",
          field: "businessRegisterNumber"
        },
        {
          label: "Email",
          field: "email"
        },
        {
          label: "Contact",
          field: "contact"
        },
        {
          label: "Fax",
          field: "fax"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "name[partial]": "",
        "registerEstateAgentName[partial]": "",
        businessRegisterNumber: "",
        email: "",
        "address[partial]": ""
      },
      isBranchDetailShown: false,
      currentBranchDetail: {}
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.getAllBranches(), this.getAccessPermission()]);
    },
    async getAccessPermission() {
      this.canAdd = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "agencyBranch.create"
      );
      this.canEdit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "agencyBranch.update"
      );
      this.canDelete = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "agencyBranch.delete"
      );
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllBranches();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllBranches().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddBranch() {
      this.$router.push({ name: "ManageBranchAdd" });
    },
    toEditBranch(id) {
      this.$router.push({ name: "ManageBranchEdit", params: { id: id } });
    },
    toManageBankAccounts(id) {
      this.$router.push({
        name: "ManageBranchBankAccounts",
        params: { id: id }
      });
    },
    openBranchDetail(event) {
      this.currentBranchDetail = event.row;
      this.isBranchDetailShown = true;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllBranches();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllBranches();
    },
    // ========================== API Related =========================
    async getAllBranches() {
      this.$store.commit("setIsLoading", true);
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.$store.dispatch("manageBranch/getAllBranches", {
          queries: filteredParam,
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.branchTableData = this._.cloneDeep(data.data);
        this.branchTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    }
  }
};
</script>

<style>
.manage-branch {
}
</style>
